<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="d-block d-md-flex justify-content-between">
        <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
          <li>
            <span>Model</span>
          </li>
          <li>
            <router-link to="/demo/digitalpoem/stats">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold" v-if="activeTab === 'Causal Insights'">
            Causal Insights
          </li>
          <li class="ProximaNovaBold" v-if="activeTab === 'Future Planning'">
            Future Planning
          </li>
          <li class="ProximaNovaBold" v-if="activeTab === 'ROI Summary'">
            ROI Summary
          </li>
        </ul>
        <div>
          <div class="" style="height: 40%">
            <div class="w-100 p-4 pr-5">
              <w-white-dropdown
                :options="marketDropdown"
                :selectedOption="selectedMarket"
                :labelText="'Market type'"
                class="mr-3"
                :placeHolder="selectedMarket.text"
                @input="selectMarket($event)"
              ></w-white-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5">
      <w-tab :tabName="activeTab" :tabs="tabs" @activeTab="changeTab($event)">
      </w-tab>
      <div v-if="activeTab === 'Causal Insights'">
        <div class="mt-5" :class="breakDown ? 'card' : ''">
          <b-row class="no-gutters">
            <b-col lg="4" :class="breakDown ? '' : 'card'">
              <div>
                <pie-chart
                  title="Overall"
                  :colors="pieChartColor"
                  :series="overallSeries"
                  :showBreakdown="true"
                  :breakdown="breakDown"
                  @getBreakdown="getBreakdown"
                />
              </div>
            </b-col>
            <b-col lg="8" v-if="breakDown" class="breakdownable-card">
              <multiple-donut-chart
                :series1="externalData"
                :series2="internalData"
                :title="multipleTitle"
              />
            </b-col>
          </b-row>
        </div>
        <div class="card mt-5 p-5">
          <b-container fluid>
            <Content
              :stripData="mediaData"
              :title="'Media'"
              :breakeDownShow="false"
              :showBreakdownIcon="false"
            />
          </b-container>
        </div>
        <div class="card mt-5 p-5">
          <b-container fluid>
            <Digital
              :data="digitalExpandData"
              :stripData="digitalData"
              @getDataBreakdown="getDigitalBreakdown"
            />
          </b-container>
        </div>
        <div class="card mt-5 p-5">
          <b-container fluid>
            <Content
              :barData="contentExpandData"
              :stripData="contentData"
              @getDataBreakdown="getContentBreakdown"
            />
          </b-container>
        </div>
      </div>
      <w-summary v-if="activeTab === 'ROI Summary'"></w-summary>
      <w-future-planning
        v-if="activeTab === 'Future Planning'"
      ></w-future-planning>
    </div>
  </div>
</template>
<script>
import Tab from "@/components/Solutions/Tab.vue";
import PieChart from "@/components/Chart/PieChart.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
import ROISummary from "./Summary.vue";
import FuturePlanning from "./FuturePlanning.vue";
import { DigitalPoemServices } from "@/services/DigitalPoemServices.js";
const digitalPoemService = new DigitalPoemServices();

export default {
  name: "Insights",
  components: {
    "w-summary": ROISummary,
    "w-tab": Tab,
    "w-white-dropdown": WhiteDropdown,
    "w-future-planning": FuturePlanning,
    PieChart,
    Digital,
    Content,
    MultipleDonutChart,
  },
  data() {
    return {
      breakDown: true,
      colors: [
        "#ffd740",
        "#18ffff",
        "#69f0ae",
        "#ff9100",
        "#ff5252",
        "#1e88e5",
        "#5e35b1",
        "#00897b",
      ],
      pieChartColor: ["#ff4081", "#64ffda"],
      contentBreakDown: false,
      activeTab: "Causal Insights",
      activeFrom: 1,
      selectedMarket: "",
      tabs: [
        {
          category: "Causal Insights",
        },
        {
          category: "Future Planning",
        },
        {
          category: "ROI Summary",
        },
      ],
      marketDropdown: [
        { text: "All India", id: "allindia" },
        { text: "USA", id: "allindia" },
        { text: "HSM", id: "hsm", disable: true },
        { text: "KER", id: "ker", disable: true },
        { text: "KA", id: "KA", disable: true },
      ],
      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
              selected: true,
            },
            {
              name: "External",
              y: 77,
            },
          ],
        },
      ],
      digitalData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 61,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 39,
          backgroundColor: "#b5a9ff",
        },
      ],
      externalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Consumer Sentiment",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              // display only if larger than 1
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      mediaData: [
        { label: 'Digital', value: 82, backgroundColor: 'rgb(142, 36, 170)' },
        { label: 'Content', value: 18, backgroundColor: 'rgb(251, 140, 0)' },
      ],
      internalData: [
        {
          name: "Media",
          y: 55,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Product",
          y: 21,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Promotions",
          y: 14,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Distribution",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function() {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      contentExpandData: {
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#8394b4",
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#ff5252",
          "#1e88e5",
          "#5e35b1",
          "#00897b",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [
          {
            name: "Direct Posts",
            data: [10],
            pointWidth: 16,
            yAxis: 1,
          },
          {
            name: "Product reviews",
            data: [31],
            pointWidth: 16,
            yAxis: 1,
          },
          {
            name: "Influencer mentions",
            data: [63],
            pointWidth: 16,
            yAxis: 1,
          },
          {
            name: "Interviews",
            data: [20],
            pointWidth: 16,
            yAxis: 1,
          },
        ],
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
      },
      digitalExpandData: [],
      multipleTitle: ["External", "Internal"],
    };
  },

  created() {
    this.selectedMarket = JSON.parse(sessionStorage.getItem("kpiMarket"));
    this.getOverallChartData();
    this.getExternalData();
    sessionStorage.removeItem("edited");
    this.getFirstLevelData("digitalData", "digital");
    this.getFirstLevelData("contentData", "content");
  },
  methods: {
    getFirstLevelData(dataVariable, param) {
      var color = [];
      if (param === "content") {
        color = ["#fb8c00", "#ffb74d"];
      } else {
        color = [
          "#8e24aa",
          "#8e24aa",
          "#ab47bc",
          "#ce93d8",
          "#e1bee7",
          "#8e24aa",
          "#8e24aa",
          "#ab47bc",
          "#ce93d8",
          "#e1bee7",
        ];
      }
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          param,
          this.selectedMarket.id
        )
        .then((res) => {
          this[dataVariable] = [];
          for (var i = 0; i < res.length; i++) {
            this[dataVariable].push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: color[i],
            });
          }
        });
    },
    getExternalData() {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          "external factors",
          this.selectedMarket.id
        )
        .then((res) => {
          this.externalData = [];
          for (var i = 0; i < res.length; i++) {
            var name = ""
            if(res[i].name === 'GDP'){
              name = "Consumer Sentiment"
            }else{
              name = res[i].name
            }
            this.externalData.push({
              name: name,
              y: res[i].value,
              z: 92.9,
              dataLabels: {
                formatter: function() {
                  return this.y > 1
                    ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                    : null;
                },
              },
              id: "id" + i,
            });
          }
        });
    },
    getOverallChartData() {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          "overall",
          this.selectedMarket.id
        )
        .then((res) => {
          this.overallSeries[0].data = [];
          for (var i = 0; i < res.length; i++) {
            this.overallSeries[0].data.push({
              name: res[i].name,
              y: res[i].value,
              sliced: true,
            });
          }
        });
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    getContentBreakdown(data) {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.contentExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.contentExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getDigitalBreakdown(data) {
      digitalPoemService
        .getChartData(
          "sales causality-digitalpoem",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.digitalExpandData = [];
          var count = 0;
          for (var i = 0; i < res.length; i++) {
            this.digitalExpandData.push({
              name: res[i].name,
              pointWidth: 20,
              data: [
                {
                  x: count,
                  x2: count + res[i].value,
                  y: i + 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: true,
              },
            });
            count += res[i].value;
          }
        });
    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
    },
    selectMarket(e) {
      var defaultOption = JSON.parse(sessionStorage.getItem("kpiMarket"));
      if (e === "Select") {
        if (e === this.selectedMarket) {
          return;
        }
        this.selectedMarket = defaultOption;
        return;
      }
      if (defaultOption.text === e.text) {
        return;
      }
      this.selectedMarket = e;
      sessionStorage.setItem("kpiMarket", JSON.stringify(e));
      location.reload();
    },
  },
};
</script>

<style scoped>
.page-wrapper {
  margin-top: 71px;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.item {
  margin-top: 20px;
}

.title {
  font-family: ProximaNovaRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  margin-bottom: 5px;
}

::v-deep .progress-bar {
  text-align: end;
  padding-right: 6px;
}
</style>
